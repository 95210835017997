import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import thanks from '../images/svg/thanks.svg'

const ThanksTeam = () => {
  return (
    <Layout>
      <div className="px-5 md:px-20 py-10 text-center">
        <img className="mx-auto" src={thanks} alt="obrigado"/>
        <h1 className="text-center text-4xl font-bold mt-10 text-primary">Cadastro realizado!</h1>
        <div className="text-center text-2xl font-bold mt-10 text-gray-600"> Obrigado por enviar seu currículo! :)</div>
        <div className="pt-5">
          <Link
            to="/"
            className='w-full bottom-3 mx-auto mt-10 bg-accent text-primary-400 py-2 px-10 rounded-2xl text-xl font-bold overflow-hidden'>
            Voltar ao site
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default ThanksTeam